<template>
  <!-- <div class="row" style="margin-top: 1.5cm; width: 100%"> -->
  <div class="d-flex flex-column flex-md-row px-0">
    <b-navbar :toggleable="toggle" type="dark" variant="success" fixed="top" v-show="$globalData.LoginOK == 'yes'"
      class="float-container">
      <b-navbar-brand href="#">Baiturrahman Mobile<sup>
          <b-badge class="mt-0 ml-1">1.1
            <!-- {{ $globalData.versiaplikasi }} -->
          </b-badge></sup></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <li class="nav-item float-right">
            <div class="nav-link" id="Mypopover" style="cursor: pointer !important">
              <!-- {{ $globalData.TokenOK == 1 ? this.$globalData.UserId : "" }} -->
              <b-badge variant="light"><span class="badge">Periode: {{ $globalData.CurrentYear }}</span></b-badge>
            </div>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link" @click="logout">Logout</a>
            <!-- <router-link to="/" class="text-white">Logout</router-link> -->
            <!-- <router-link @click="logout" class="text-white">Logout</router-link> -->
          </li>
        </b-navbar-nav>
      </b-collapse>
      <!-- </div> -->
    </b-navbar>

    <!-- Sidebar for desktop -->
    <!-- <div class="p-2 bg-dark d-none d-md-block" style="width: 200px"> -->
    <div class="p-2 bg-dark d-none d-md-block">
      <b-collapse v-model="isCollapsed" appear>
        <MenuComponent :isactive="isactive" @menu="menu" />
      </b-collapse>
    </div>

    <div>
      <!-- login {{ $globalData.LoginOK }} -->
      <router-view />
    </div>

    <!-- Sidebar for mobile -->
    <b-sidebar id="sidebar" title="Menu" :visible.sync="isSidebarVisible" backdrop class="d-md-none"
      v-show="!$globalData.datauser">
      <MenuComponent :isactive="isactive" @menu="menu" />
    </b-sidebar>
  </div>
</template>

<script>
import MenuComponent from "./views/MenuComponent.vue";

export default {
  components: {
    MenuComponent,
  },
  data() {
    return {
      isSidebarVisible: false,
      isCollapsed: true,
      isactive: "dashboard", // Ganti dengan data yang sesuai
      islogin: false,
      toggle: true,
    };
  },
  computed: {
    mainContentClass() {
      return {
        "col-md-10": this.isCollapsed,
        "col-md-12": !this.isCollapsed,
        "col-12": !this.islogin, // Full width on mobile if not logged in
      };
    },
  },
  methods: {
    logout() {
      this.toggle = true;
      this.$router.push({ path: "/" });
    },
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    menu(item) {
      this.isactive = item;
      console.log("menu item", item, this.isactive);
      // Lakukan aksi lainnya saat item menu diklik
    },

  },
};
</script>

<style scoped>
@media (max-width: 767.98px) {

  .col-md-10,
  .col-md-12 {
    width: 100% !important;
  }
}
</style>

<!-- <template>
  <div id="app">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

nav {
  padding: 10px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style> -->
