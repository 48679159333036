<template>
  <div class="container text-center px-1" style="margin-top: 1.5cm !important">
    <b-card
      class="col-md-4 px-0 mt-4 mb-4 text-center shadow"
      no-body
      :header="'Adm. Perizinan ' + $globalData.CurrentYear"
    >
      <div class="row mt-2 mb-2 text-left">
        <div class="col-4 ml-2">Periode :</div>
        <div class="col-6">
          <b-form-select
            :options="['Today', 'Week', 'Month']"
            size="sm"
            class="ml-2"
            v-model="opt"
            @change="refresh"
          />
        </div>
      </div>
      <div class="row mb-2 text-left">
        <div class="col-4 ml-2">Search Name :</div>
        <div class="col-6">
          <b-form-input
            size="sm"
            class="ml-2"
            v-model="search"
            @change="refresh"
          />
          <b-icon-arrow-repeat
            @click="refresh"
            class="position-absolute"
            style="top: 10px; right: -20px; z-index: 100; cursor: pointer"
            title="Refresh"
            size="sm"
          />
        </div>
      </div>

      <!-- {{ opt }} -->
      <b-table
        :items="info"
        hover
        responsive
        :busy="isBusy"
        style="font-size: 13px"
        class="text-left"
        @row-clicked="dataselected"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" size="sm"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
    </b-card>
    <br />
    <br />
    <br />
    <b-modal v-model="modal" :title="'Approve an. ' + rowizin.nama" ok-only>
      <template #modal-footer>
        Approved: {{ rowizin.tglapprove }}({{ rowizin.approvedby }})
        <b-button
          size="sm"
          :disabled="rowizin.noizin != ''"
          @click="izinok(rowizin.kodeunix)"
          ><i class="bi bi-bookmark-check"></i> SETUJU
        </b-button>
      </template>
      <div class="row">
        <div class="col">
          <qr-code :text="rowizin.noizin" :size="200" />
        </div>
        <div class="col" style="font-size: 13px">
          dari Tgl:<br />
          {{ rowizin.mulai }} {{ rowizin.jamkeluar }}<br />
          SD.<br />
          {{ rowizin.sampai }} {{ rowizin.jamdatang }}<br />
          Alasan:<br />
          {{ rowizin.alasanizin }}<br />
          Scan Keluar:<br />
          {{ rowizin.scankeluar }}<br />
          Scan Masuk:<br />
          {{ rowizin.scanmasuk }} <br />
          Approved:<br />
          {{ rowizin.tglapprove }} {{ rowizin.approvedby }}
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AdminIzin",
  components: {
    //  Navbar,
  },
  data() {
    return {
      password: "",
      tgllahir: "",
      ketlogin: "",
      urladdress: "https://apps.baiturrahman.com/php.script/",
      opt: "Today",
      search: "",
      info: [],
      isBusy: false,
      modal: false,
      rowizin: {},
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    izinok(kode) {
      // alert(kode);
      axios
        .get(
          this.$appUrl +
            "approveizin.php?kode=" +
            kode +
            "&user=" +
            this.$globalData.UserId
        )
        .then((res) => {
          this.rowizin.noizin = res.data[0]["noizin"];
          this.rowizin.tglapprove = res.data[0]["tglapprove"];
          this.rowizin.approvedby = res.data[0]["approvedby"];
        });
    },
    dataselected(item) {
      console.log(item);
      this.rowizin = item;
      this.modal = true;
    },
    refresh() {
      axios
        .get(
          this.$appUrl + "getizin.php?jenis=" + this.opt + "&q=" + this.search
        )
        .then((res) => {
          this.info = res.data;
        });
    },
  },
};
</script>

<style></style>
