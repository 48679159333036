var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container text-center",style:({
  backgroundImage: `url(${this.bgurl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh',
  width: '100vw',
  marginTop: '-10px'
})},[_c('br'),_c('br'),_vm._m(0),_c('br'),_c('br'),_c('br'),_c('b-card',{staticClass:"col-md-4 px-0 mt-4 mb-4 text-center shadow",staticStyle:{"opacity":"1"},attrs:{"header":"Login Page"}},[_c('b-form',[_c('b-input-group',{attrs:{"size":"md"}},[_c('b-form-input',{attrs:{"placeholder":"NIS santri"},model:{value:(_vm.$globalData.UserId),callback:function ($$v) {_vm.$set(_vm.$globalData, "UserId", $$v)},expression:"$globalData.UserId"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('b-icon-person-check')],1)],1),_c('b-input-group',{staticClass:"mt-2",attrs:{"size":"md"}},[_c('b-form-input',{attrs:{"type":_vm.passwordtype ? 'password' : 'text',"placeholder":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('b-icon-key',{on:{"click":function($event){_vm.passwordtype = !_vm.passwordtype}}})],1)],1)],1),_c('p',{staticClass:"text-bottom",staticStyle:{"font-size":"10px"}},[_vm._v(" password pertama = tgllahir (yyyy-mm-dd) ")]),_c('b-button',{staticClass:"float-right mt-1 col-6",attrs:{"block":""},on:{"click":_vm.gologin}},[_c('b-icon-arrow-right'),_vm._v("Login")],1)],1),_c('br'),_c('br'),_c('br'),_c('br'),_c('p',{staticClass:"text-bottom",staticStyle:{"font-size":"12px"}},[_vm._v(" Baiturrahman Copyright 2024 ")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-white"},[_c('p',{staticStyle:{"font-size":"30px"}},[_vm._v("Baiturrahman Mobile")]),_c('p',[_vm._v("Aplikasi untuk Orangtua Santri")])])
}]

export { render, staticRenderFns }