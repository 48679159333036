<template>
  <div class="container text-center px-1" style="margin-top: 1.5cm !important">
    <b-card class="col-md-4 px-0 mt-4 mb-2 text-center shadow" no-body>
      <template #header>
        <a @click="help"><span style="font-size: 17px">Perkembangan Ibadah Santri </span></a><br /><b>{{ data.nama
          }}</b>
      </template>
      <div class="row justify-content-center">
        Tahun:
        <b-link v-for="(bl, k) in bulan" :key="k" @click="refresh('summary', bl.bulan)">{{ bl.bulan }},
        </b-link>
      </div>
      <b-icon-arrow-repeat @click="refresh('bulan', '')" class="position-absolute" title="Refresh" size="sm"
        style="top: 21px; right: 20px; z-index: 100; cursor: pointer" />
      <div>
        <b-table :items="nilai" :fields="[
          'keterangan',
          'jan',
          'feb',
          'mar',
          'apr',
          'may',
          'jun',
          'jul',
          'aug',
          'sep',
          'oct',
          'nov',
          'dec',
        ]" hover responsive :busy="isBusy" style="font-size: 12px; width: 98%" class="text-left" small>
          <template #cell(keterangan)="data">
            <div class="sticky-column">
              {{ data.item.keterangan }}
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" size="sm"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-link v-show="false" v-for="(bl, k) in judul" :key="k" @click="changedata(bl.keterangan)"
      style="font-size: 13px">{{
        bl.keterangan
      }}|
    </b-link>
    <!-- <b-link @click="changedata('1')">Channge 1</b-link>
    <b-link @click="changedata('2')">Channge 2</b-link> -->

    <div>
      <line-chart ref="chart" :data="chartData" :options="chartOptions" v-show="false"></line-chart>
    </div>
    <!-- {{ datacht }}
    {{ nilai }} -->

    <!-- <br /> -->
    <Aktivitasmrj :data="$globalData.datauser" />
  </div>
</template>

<script>
import axios from "axios";
import { Line } from "vue-chartjs";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import Aktivitasmrj from "@/views/aktivitasmrj.vue";

export default {
  name: "PenilaianSantri",
  props: ["data"],
  components: { LineChart: Line, Aktivitasmrj },

  data() {
    return {
      isBusy: false,
      nilai: [],
      bulan: [],
      judul: [],
      dataset: "",
      re_render: true,
      datacht: [0, 0, 0, 0, 4, 9, 10, 20, 19, 31, 4, 5],
    };
  },
  computed: {
    chartData() {
      return {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: this.dataset,
            backgroundColor: "blue",
            data: this.datacht,
          },
          //  {
          //    label: this.dataset,
          //    backgroundColor: "red",
          //    data: [0, 10, 5, 0, 4, 9, 10, 20, 19, 0, 4, 0],
          //  },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          y: {
            suggestedMin: 0,
            suggestedMax: 30,
          },
        },
      };
    },
  },
  mounted() {
    axios.get(this.$appUrl + "getjudulnilai.php").then((res) => {
      this.judul = res.data;
    });
    this.refresh("bulan");
    //  this.changedata("1");
  },
  methods: {
    help() {
      this.$bvModal.msgBoxOk(
        "Disini Dijelaskan mengenai Penilaian yang dilakukan oleh pihak Pesantren terhadap santri;  diantaranya Sholat malam, murajaah, Mentoring, dll.",
        {
          centered: true,
        }
      );
    },
    changedata(para) {
      this.re_render = true;
      if (para == "1") {
        this.datacht = [20, 30, 50, 60, 70, 80, 90];
        this.dataset = "Data 7 bln";
      }
      const months = [
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec",
      ];

      const result =
        this.nilai
          .filter((item) => item.keterangan === para)
          .map((item) =>
            months.map((month) => (item[month] === null ? 0 : item[month]))
          )[0] || [];

      this.datacht = result;
      this.dataset = para;
    },
    refresh(para, bln) {
      this.isBusy = true;
      axios
        .get(
          this.$appUrl +
          "getnilai.php?token=" +
          this.$globalData.Token +
          "&nis=" +
          this.data.nis +
          "&bulan=" +
          bln +
          "&jenis=" +
          para
        )
        .then((res) => {
          if (para == "bulan") {
            this.bulan = res.data;
          } else {
            this.nilai = res.data;
          }
          this.isBusy = false;
        });
    },
  },
};
</script>

<style></style>
