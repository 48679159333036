export const func = {
  cekhak(data, keyword) {
    return data.some(function (item) {
      return item.nopmb.toLowerCase().includes(keyword.toLowerCase());
    });
  },

  NumberFormat: (data) => {
    //console.log("funct",data)
    if (data == "" || data == null) {
      return "";
    }
    // return data.toLocaleString('en-US');
    else data = Math.floor(data);
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  NFormat: (data, dec) => {
    const format = (num, dec) =>
      num.toLocaleString("en-US", {
        minimumFractionDigits: dec,
        maximumFractionDigits: dec,
      });
    return format(data, dec);
  },
  replaceAll: (str, find, replace) => {
    return str.replace(new RegExp(find, "g"), replace);
  },
  getumur(tg1, tg2) {
    var date1 = new Date(tg1);
    var date2 = new Date(tg2);
    var diffDay = (date2 - date1) / (1000 * 60 * 60 * 24);
    var thn = parseInt(diffDay / 365.25);
    return thn;
  },
  titleCase(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  },
  selisihtanggal(tg1, tg2) {
    var diff = Math.floor((Date.parse(tg2) - Date.parse(tg1)) / 86400000);
    return diff;
  },
  terbilang(angka) {
    var bilne = [
      "",
      "Satu",
      "Dua",
      "Tiga",
      "Empat",
      "Lima",
      "Enam",
      "Tujuh",
      "Delapan",
      "Sembilan",
      "Sepuluh",
      "Sebelas",
    ];

    if (angka < 12) {
      return bilne[angka];
    } else if (angka < 20) {
      return this.terbilang(angka - 10) + " Belas";
    } else if (angka < 100) {
      return (
        this.terbilang(Math.floor(parseInt(angka) / 10)) +
        " Puluh " +
        this.terbilang(parseInt(angka) % 10)
      );
    } else if (angka < 200) {
      return "Seratus " + this.terbilang(parseInt(angka) - 100);
    } else if (angka < 1000) {
      return (
        this.terbilang(Math.floor(parseInt(angka) / 100)) +
        " Ratus " +
        this.terbilang(parseInt(angka) % 100)
      );
    } else if (angka < 2000) {
      return "seribu " + this.terbilang(parseInt(angka) - 1000);
    } else if (angka < 1000000) {
      return (
        this.terbilang(Math.floor(parseInt(angka) / 1000)) +
        " Ribu " +
        this.terbilang(parseInt(angka) % 1000)
      );
    } else if (angka < 1000000000) {
      return (
        this.terbilang(Math.floor(parseInt(angka) / 1000000)) +
        " Juta " +
        this.terbilang(parseInt(angka) % 1000000)
      );
    } else if (angka < 1000000000000) {
      return (
        this.terbilang(Math.floor(parseInt(angka) / 1000000000)) +
        " Milyar " +
        this.terbilang(parseInt(angka) % 1000000000)
      );
    } else if (angka < 1000000000000000) {
      return (
        this.terbilang(Math.floor(parseInt(angka) / 1000000000000)) +
        " Trilyun " +
        this.terbilang(parseInt(angka) % 1000000000000)
      );
    }
  },
  tglind(tg, showhari, newtg) {
    var months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    var myDays = [
      "Minggu",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jum&#39;at",
      "Sabtu",
    ];

    var date = new Date(tg);
    date = newtg == "1" ? new Date() : date;
    var day = date.getDate();
    var month = date.getMonth();
    var thisDay = date.getDay();
    thisDay = showhari == "1" ? myDays[thisDay] + ", " : "";
    var yy = date.getYear();
    var year = yy < 1000 ? yy + 1900 : yy;

    return thisDay + day + " " + months[month] + " " + year;
  },
  checkData(data) {
    // Mengecek apakah karakter pertama adalah angka
    if (/^\d/.test(data)) {
      return "date";
    } else {
      return "password";
    }
  },
  tglhariini() {
    var tgl = new Date();
    var tg = tgl.getDate();
    var bl = tgl.getMonth() + 1;
    bl = bl < 10 ? "0" + bl : bl;
    tg = tg < 10 ? "0" + tg : tg;
    var yy = tgl.getFullYear();
    return yy + "-" + bl + "-" + tg;
  },
};
