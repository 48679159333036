<template>
  <div class="container">
    <div class="main-content">
      <!-- Konten utama, seperti carousel -->
      <b-card class="col-md-4 px-0 mt-4 mb-4 text-center shadow" no-body header="Info PONPES">
        <b-carousel id="carousel-1" controls indicators background="#ababab" :interval="4000" v-model="slide"
          img-width="500" img-height="480" class="mt-2">
          <!-- :caption="image" -->
          <b-carousel-slide v-for="(image, index) in info" :key="index" :img-src="$appUrl + `/images/${image}`">
          </b-carousel-slide>
        </b-carousel>
      </b-card>
    </div>

    <div class="social-media-container">
      <h2 style="font-size: 16px;">Media Sosial</h2>
      <div class="social-media-links">
        <a href="https://www.youtube.com/@pesantrenbaiturrahman" target="_blank" aria-label="YouTube">
          <i class="bi bi-youtube"></i>
          <span>YouTube</span>
        </a>
        <a href="https://baiturrahman.com/" target="_blank" aria-label="Website">
          <i class="bi bi-globe"></i>
          <span>Website</span>
        </a>
        <a href="https://www.instagram.com/ponpesbaiturrahman/" target="_blank" aria-label="Instagram">
          <i class="bi bi-instagram"></i>
          <span>Instagram</span>
        </a>
        <a href="https://www.facebook.com/officialbaiturrahman" target="_blank" aria-label="Facebook">
          <i class="bi bi-facebook"></i>
          <span>Facebook</span>
        </a>
        <a href="https://wa.link/scp19c" target="_blank" aria-label="WhatsApp">
          <i class="bi bi-whatsapp"></i>
          <span>WhatsApp</span>
        </a>
        <a href="https://www.tiktok.com/@ponpesbaiturrahman" target="_blank" aria-label="TikTok">
          <i class="bi bi-tiktok"></i>
          <span>TikTok</span>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "InfoData",
  components: {
    //  Navbar,
  },
  data() {
    return {
      password: "",
      tgllahir: "",
      ketlogin: "",
      urladdress: "https://apps.baiturrahman.com/php.script/",
      info: [],
      isBusy: false,
      slide: 0,
    };
  },
  mounted() {
    axios
      .get(this.$appUrl + "getnamafile.php?token=" + this.$globalData.Token)
      .then((res) => {
        this.info = res.data;
      });
  },
  methods: {},
};
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  margin-top: 1.5cm;
  /* Pastikan kontainer memanjang ke seluruh tinggi viewport */
}

.main-content {
  flex: 1;
  /* Membuat konten utama mengisi ruang yang tersisa */
}

.social-media-container {
  text-align: center;
  margin-top: 5px;
}

.social-media-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* Jarak antar item */
  justify-items: center;
  align-items: center;
}

h2 {
  font-size: 16px;
  margin-bottom: 20px;
}

a {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  /* Ukuran font untuk teks */
}

i {
  font-size: 2em;
  /* Ukuran ikon */
  transition: color 0.3s, transform 0.3s;
}

a:hover i {
  color: #007bff;
  /* Warna saat hover */
  transform: scale(1.1);
  /* Efek zoom saat hover */
}
</style>
