<!-- MenuComponent.vue -->
<template>
  <b-nav vertical class="text-white" pills>
    <b-nav-item
      class="classhover"
      @click="pilihmenu('dashboard')"
      :active="isactive === 'dashboard'"
    >
      <i class="fa-solid fa-chart-bar mr-1"></i>
      Dashboard
    </b-nav-item>
    <b-nav-item
      class="classhover"
      @click="pilihmenu('setting')"
      :active="isactive === 'setting'"
    >
      <i class="fa-solid fa-chart-bar mr-1"></i>
      Setting
    </b-nav-item>
    <b-nav-item
      class="classhover"
      @click="pilihmenu('setupwali')"
      :active="isactive === 'setupwali'"
    >
      <i class="fa-solid fa-chart-bar mr-1"></i>
      Setup Wali Asrama
    </b-nav-item>
    <!-- Tambahkan item menu lainnya di sini -->
  </b-nav>
</template>

<script>
export default {
  name: "MenuComponent",
  props: {
    // isactive: String,
  },
  data() {
    return {
      isactive: "",
    };
  },
  methods: {
    pilihmenu(mn) {
      this.$emit("menu", mn);
      this.isactive = mn;
    },
  },
};
</script>

<style>
.classhover:hover {
  background-color: lightblue !important;
  color: white !important;
}
</style>
