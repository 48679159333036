<template>
  <div class="container text-center px-1" style="margin-top: 1.5cm !important">
    <b-card
      class="col-md-4 px-0 mt-4 mb-4 text-center shadow"
      header="Perizinan Santri"
    >
      <h5>{{ data.nama }}</h5>
      <b-card-text>
        <div class="col-11 mt-3 text-left">
          <span class="ml-0"> Izin untuk Tanggal: </span>
          <b-input-group size="sm">
            <b-form-input type="date" class="col-8" v-model="form.tg1" />
            <b-form-input type="time" class="col-4" v-model="form.jam1" />
          </b-input-group>
        </div>
        <div class="col-11 mt-3 text-left">
          <span class="ml-0"> Sampai Tanggal: </span>
          <b-input-group size="sm">
            <b-form-input type="date" class="col-8" v-model="form.tg2" />
            <b-form-input type="time" class="col-4" v-model="form.jam2" />
          </b-input-group>
        </div>
        <div class="row mt-3">
          <span class="ml-3"> Alasan Izin</span>
          <b-form-textarea size="sm" v-model="form.alasan" />
        </div>
        <b-button
          size="sm"
          class="mt-1 text-right"
          variant="success"
          @click="submits"
          >Submit</b-button
        >
      </b-card-text>

      <div style="position: relative">
        <!-- class="position-absolute" -->
        <b-icon-arrow-repeat
          @click="refresh"
          class="position-absolute"
          style="top: -21px; right: 1px; z-index: 100; cursor: pointer"
          title="Refresh"
          size="sm"
        />
        <b-table
          :items="dataizin"
          :fields="['tanggal', 'noizin', 'mulai', 'alasanizin']"
          responsive
          hover
          style="font-size: 13px"
          @row-clicked="dataselected"
        />
      </div>
      <!-- {{ dataizin }} -->
      <b-carousel
        id="carousel-1"
        controls
        indicators
        background="#ababab"
        :interval="4000"
        v-model="slide"
        class="mt-2"
        v-show="false"
      >
        <b-carousel-slide
          v-for="(item, index) in dataslide"
          img-blank
          :key="index"
          :caption="item.nama"
        >
          <div @click="getslide(item)">
            <div class="text-center">
              <qr-code :text="item.tglawal" :size="200" />
            </div>
            <!-- <p class="text-white">{{ item.tglawal }}</p>
            <p class="text-white">{{ item.tglakhir }}</p> -->
            <p class="text-white">{{ item.tglawal }} sd {{ item.tglakhir }}</p>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </b-card>
    <b-modal v-model="modal" :title="noizin + '  ' + data.nama" ok-only>
      <div class="row">
        <div class="col">
          <qr-code :text="noizin" :size="200" />
        </div>
        <div class="col" style="font-size: 13px">
          dari Tgl:<br />
          {{ rowizin.mulai }}<br />
          SD.<br />
          {{ rowizin.sampai }}<br />
          Scan Keluar:<br />
          {{ rowizin.scankeluar }}<br />
          Scan Masuk:<br />
          {{ rowizin.scanmasuk }} <br />
          Approved:<br />
          {{ rowizin.tglapprove }} {{ rowizin.approvedby }}
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "IzinSantri",
  components: {
    //  Navbar,
  },
  props: ["data"],
  data() {
    return {
      urladdress: "https://apps.baiturrahman.com/php.script/",
      info: [],
      noizin: "",
      modal: false,
      isBusy: false,
      dataslide: [
        {
          nis: "001",
          nama: "John Doe",
          tglawal: "2023-01-01",
          tglakhir: "2023-01-07",
          setuju: "Ya",
        },
        {
          nis: "002",
          nama: "Jane Smith",
          tglawal: "2023-02-01",
          tglakhir: "2023-02-07",
          setuju: "Tidak",
        },
        // tambahkan data lainnya
      ],
      slide: 0,
      form: {
        tg1: "",
        tg2: "",
        jam1: "",
        jam2: "",
        alasan: "",
      },
      dataizin: [],
      rowizin: {},
    };
  },
  mounted() {
    this.form.tg1 = this.$func.tglhariini();
    this.form.tg2 = this.$func.tglhariini();
    this.form.jam1 = "15:00";
    this.form.jam2 = "15:00";
    //   axios
    //     .get(this.$appUrl + "getkalendar.php?token=" + this.$globalData.Token)
    //     .then((res) => {
    //       this.info = res.data;
    //     });
  },
  methods: {
    dataselected(item) {
      if (item.noizin != "") {
        this.noizin = item.noizin;
        this.rowizin = item;
        this.modal = true;
      }
    },
    getslide(item) {
      console.log("slide", item);
    },
    refresh() {
      axios
        .get(
          this.$appUrl +
            "refreshizin.php?nis=" +
            this.data.nis +
            "&token=" +
            this.$globalData.Token
        )
        .then((res) => {
          this.dataizin = res.data;
        });
    },
    submits() {
      const today = this.$func.tglhariini();
      const selected = this.form.tg1;
      const selected2 = this.form.tg2;
      console.log("today", today, selected);
      if (selected < today) {
        this.$bvModal.msgBoxOk("Tanggal < Hari ini not Valid");
        return;
      }
      if (selected2 < selected) {
        this.$bvModal.msgBoxOk("Awal > Akhir not Valid");
        return;
      }
      if (this.form.alasan == "") {
        this.$bvModal.msgBoxOk("Alasan harus Diisi...");
        return;
      }
      this.$bvModal
        .msgBoxConfirm("Yakin untuk di SUBMIT ?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post(
                this.$appUrl +
                  "submitizin.php?nis=" +
                  this.data.nis +
                  "&token=" +
                  this.$globalData.Token,
                this.form
              )
              .then((res) => {
                console.log("response", res.data);
                this.refresh();
              });
          }
        });
    },
  },
};
</script>

<style></style>
