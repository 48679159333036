<template>
  <div class="container text-center px-1" style="margin-top: 1.5cm !important">
    <b-card
      class="col-md-4 px-0 mt-4 mb-4 text-center shadow"
      no-body
      :header="'Kalendar Akademik ' + $globalData.CurrentYear"
    >
      <b-table
        :items="info"
        hover
        responsive
        :busy="isBusy"
        style="font-size: 13px"
        class="text-left"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" size="sm"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
    </b-card>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "KalendarData",
  components: {
    //  Navbar,
  },
  data() {
    return {
      password: "",
      tgllahir: "",
      ketlogin: "",
      urladdress: "https://apps.baiturrahman.com/php.script/",
      info: [],
      isBusy: false,
    };
  },
  mounted() {
    axios
      .get(this.$appUrl + "getkalendar.php?token=" + this.$globalData.Token)
      .then((res) => {
        this.info = res.data;
      });
  },
  methods: {},
};
</script>

<style></style>
