<template>
  <div class="container text-center" style="margin-top: 1.2cm">
    <b-card class="col-md-4 px-0 mt-4 mb-1 text-center shadow">
      <div>
        <b-img :src="urladdress + $globalData.datauser.pasfoto" rounded width="300px" class="text-center" />
      </div>
    </b-card>
    <!-- <div contenteditable> -->
    <div class="text-right mb-3">
      <b-link @click="modal = true"><b-icon-key-fill />Ganti Password</b-link>
    </div>
    <div>
      <b>{{ data.nama }} </b>({{ data.nis }})
      <!-- <div>Nama Ayah: {{ data.namaayah }}</div>
      <div>Alamat: {{ data.alamatortu }}</div>
      <div>Kota: {{ data.kotaortu }}</div>
      <div>NoHP: {{ data.nohp }}</div> -->
    </div>
    <hr />

    <h5>Informasi Santri</h5>
    <b-table :items="info" :fields="['jenis', 'keterangan', 'namawali']" small style="font-size: 13px" hover outline
      :busy="isBusy" responsive class="text-left">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" size="sm"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>

    <b-card header="Catatan Kesehatan" class="mb-3">
      <b-icon-arrow-repeat @click="refresh" class="position-absolute" title="Refresh" size="sm"
        style="top: 21px; right: 20px; z-index: 100; cursor: pointer" />

      <b-table :items="sakit" :fields="['jenis', 'tanggal', 'keluhan', 'tindakan']" small style="font-size: 13px" hover
        outline :busy="isBusy2" responsive class="text-left" empty-text="Alhamdulillah SEHAT" show-empty>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" size="sm"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #empty>
          <br />
          <h5 class="text-center">Alhamdulillah SEHAT</h5>
        </template>
      </b-table>
    </b-card>
    <!-- {{ saldouangjajan }} -->
    <b-card :header="'Uang Saku Santri  ' + $func.NumberFormat(parseFloat(saldouangjajan))
      " class="mb-5">
      <b-icon-arrow-repeat @click="refreshuang" class="position-absolute" title="Refresh" size="sm"
        style="top: 21px; right: 20px; z-index: 100; cursor: pointer" />

      <b-table :items="uangjajan" :fields="['tanggal', 'jenis', 'jumlah']" small style="font-size: 13px" hover outline
        :busy="isBusy3" responsive class="text-left" empty-text="Tidak Ada Uang Saku" show-empty>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" size="sm"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #head(jumlah)="row">
          <div class="text-right">
            {{ row.label }}
          </div>
        </template>
        <template #cell(jumlah)="row">
          <div class="text-right">
            {{ $func.NumberFormat(parseFloat(row.item.jumlah)) }}
          </div>
        </template>
        <template #empty>
          <br />
          <h5 class="text-center">Tidak Ada Uang Saku</h5>
        </template>
      </b-table>
    </b-card>

    <br />
    <br />
    <b-modal v-model="modal" title="Ganti Password" hide-footer>
      <div class="col-10">
        <b-input-group size="md" class="mt-2">
          <b-form-input v-model="password" type="password" placeholder="password baru" />
          <b-input-group-append is-text>
            <b-icon-key-fill />
          </b-input-group-append>
        </b-input-group>
      </div>
      <b-button class="mt-3 col" size="sm" :disabled="password == ''" @click="updatepass">Update Password</b-button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProfileData",
  props: ["data"],
  components: {
    //  Navbar,
  },
  data() {
    return {
      password: "",
      tgllahir: "",
      ketlogin: "",
      urladdress: "https://apps.baiturrahman.com/php.script/",
      info: [],
      sakit: [],
      uangjajan: [],
      isBusy: false,
      isBusy2: false,
      isBusy3: false,
      modal: false,
    };
  },
  computed: {
    saldouangjajan() {
      return this.uangjajan.length > 0 ? this.uangjajan[0].saldo : 0;
    },
  },
  mounted() {
    if (
      this.$globalData.datauser.pasfoto == "" ||
      this.$globalData.datauser.pasfoto == null
    ) {
      this.$globalData.datauser.pasfoto = "foto/avatar.png";
    }
    axios
      .get(
        this.$appUrl +
        "getinfosantri.php?token=" +
        this.$globalData.Token +
        "&nis=" +
        this.data.nis
      )
      .then((res) => {
        this.info = res.data;
      });
  },
  methods: {
    updatepass() {
      axios
        .get(
          this.$appUrl +
          "updatepass.php?token=" +
          this.$globalData.Token +
          "&nis=" +
          this.data.nis +
          "&password=" +
          this.password
        )
        .then((res) => {
          console.log("update pass", res);
          this.$bvModal.msgBoxOk("Password sudah di Ganti >> " + this.password);
        });
      this.modal = false;
    },
    refresh() {
      this.isBusy2 = true;
      axios
        .get(
          this.$appUrl +
          "getsakit.php?token=" +
          this.$globalData.Token +
          "&nis=" +
          this.data.nis
        )
        .then((res) => {
          this.sakit = res.data;
          this.isBusy2 = false;
        });
    },
    refreshuang() {
      this.isBusy3 = true;
      axios
        .get(
          this.$appUrl +
          "getuangjajan.php?token=" +
          this.$globalData.Token +
          "&nis=" +
          this.data.nis
        )
        .then((res) => {
          this.uangjajan = res.data;
          this.isBusy3 = false;
        });
    },
  },
};
</script>

<style></style>
