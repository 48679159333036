<template>
  <div id="content">
    <!-- Konten utama situs web Anda -->
    <div>
      <Info :data="$globalData.datauser" v-show="activeTab == 'info'" />
      <Profile :data="$globalData.datauser" v-show="activeTab == 'profile'" />
      <Kalendar :data="$globalData.datauser" v-show="activeTab == 'kalender'" />
      <Aktivitas :data="$globalData.datauser" v-show="activeTab == 'aktivitas'" />
      <Nilai :data="$globalData.datauser" v-show="activeTab == 'nilai'" />
      <Izin :data="$globalData.datauser" v-show="activeTab == 'izin'" />
    </div>
    <!-- Menu Footer -->
    <b-navbar fixed="bottom" type="light" variant="light" class="justify-content-center custom-navbar border">
      <!-- <b-navbar fixed="bottom" type="light" variant="light" class="justify-content-around custom-navbar border"> -->
      <b-nav>
        <b-nav-item href="#" class="text-center custom-nav-item" :class="{ 'active-item': activeTab === 'info' }"
          @click="activeTabf('info')">
          <!-- <i class="bi bi-house-check"></i> -->
          <b-icon icon="house" class="custom-icon"></b-icon>
          <div>Home</div>
        </b-nav-item>
        <b-nav-item href="#" class="text-center custom-nav-item" :class="{ 'active-item': activeTab === 'profile' }"
          @click="activeTabf('profile')">
          <b-icon icon="person-bounding-box" class="custom-icon"></b-icon>
          <div>Profile</div>
        </b-nav-item>
        <b-nav-item href="#" class="text-center custom-nav-item" :class="{ 'active-item': activeTab === 'kalender' }"
          @click="activeTabf('kalender')">
          <b-icon icon="calendar" class="custom-icon"></b-icon>
          <div>Kalender</div>
        </b-nav-item>
        <b-nav-item href="#" class="text-center custom-nav-item" :class="{ 'active-item': activeTab === 'aktivitas' }"
          @click="activeTabf('aktivitas')">
          <b-icon icon="list" class="custom-icon"></b-icon>
          <div>PMB</div>
        </b-nav-item>
        <b-nav-item href="#" class="text-center custom-nav-item" :class="{ 'active-item': activeTab === 'nilai' }"
          @click="activeTabf('nilai')">
          <b-icon icon="award" class="custom-icon"></b-icon>
          <div>Ibadah</div>
        </b-nav-item>
        <b-nav-item href="#" class="text-center custom-nav-item" :class="{ 'active-item': activeTab === 'izin' }"
          @click="activeTabf('izin')">
          <b-icon icon="lock" class="custom-icon"></b-icon>
          <div>Izin</div>
        </b-nav-item>
      </b-nav>
    </b-navbar>
  </div>
</template>

<script>
import Info from "@/views/info.vue";
import Kalendar from "@/views/kalendar.vue";
import Aktivitas from "@/views/aktivitas.vue";
import Nilai from "@/views/nilailine.vue";
import Izin from "@/views/izin.vue";
import Profile from "@/views/profile.vue";

export default {
  name: "MenuUtama",
  components: { Info, Kalendar, Aktivitas, Nilai, Izin, Profile },
  data() {
    return {
      activeTab: "info", // Default active tab
    };
  },
  mounted() {
    if (!this.$globalData.datauser) {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    activeTabf(mn) {
      this.activeTab = mn;
    },
  },
};
</script>

<style>
.content {
  flex: 1;
}

b-navbar {
  flex-shrink: 0;
  width: 100%;
}

.custom-navbar {
  padding: 0.1rem 0.1rem;
}

.custom-nav {
  display: flex;
  justify-content: space-around;
}

.custom-nav-item {
  padding: 0.2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-icon {
  font-size: 2rem;
}

.custom-nav-item div {
  font-size: 1.8rem;
  font-weight: bold !important;
}

.active-item {
  background-color: #28a745;
  color: white !important;
}

.active-item .b-icon,
.active-item div {
  color: white !important;
}

/* Media Queries untuk penyesuaian di layar kecil */
@media (max-width: 576px) {
  .custom-icon {
    font-size: 1.9rem;
  }

  .custom-nav-item div {
    font-size: 0.6rem;
  }

  .custom-nav-item {
    padding: 0.1rem;
  }
}
</style>

<!-- <style>
.content {
  flex: 1;
}

b-navbar {
  flex-shrink: 0;
}


.active-item {
  background-color: #28a745; /* bg-success */
  color: white !important;
}

.active-item .b-icon,
.active-item div {
  color: white !important;
}


.custom-navbar {
  padding: 0.1rem 0.1rem;
}

.custom-nav-item {
  padding: 0rem 0rem;
}

.custom-icon {
  font-size: 1.5rem; /* Mengurangi ukuran ikon */
}

.custom-nav-item div {
  font-size: 0.6rem; /* Mengurangi ukuran teks */
  font-weight: bold !important;
}
</style> -->
